/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
    overflow: visible;
    border-color: black;
    color: black;
    page-break-after: avoid-page;
    page-break-before: avoid;
    white-space: pre-line;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
  #no-print {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  * {
    -ms-overflow-style: none;
  }
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
} */

*:focus {
  outline: none !important;
}
